import React, { useEffect, useState} from 'react'
import {Dialogos} from './Dialogos'
import {Loader} from './Loader'
import {Box,Button,TextField,FormControlLabel,Radio,FormControl,FormLabel,RadioGroup,Typography, Card,CardContent} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Axios from 'axios'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Logo from '../componentes/Logo';
import Excel from './Excel'

const regex_solo_numeros = /^[0-9\b]+$/;

const columnas =   [
    {label:'Nro',value:'id_evento_inscr'},
    {label:'Nombre',value:'nombre'},
    {label:'Documento',value:'nro_documento'},
    {label:'Edad',value:'edad'},
    {label:'Item pagado',value:'detalle'},
    {label:'Provincia',value:'provincia'},
    {label:'Email',value:'email'},
    {label:'Tipo',value:'categoria'},
    {label:'Rango',value:'rango'},
    {label:'Región',value:'region'},
    {label:'Fecha alta',value:'f_alta'},
    {label:'Estado pago',value:'cod_interno'},
    {label:'Número mercadopago',value:'mp_id'},
    {label:'Importe',value:'importe'},
    {label:'Código inscripción',value:'codigo_web'},
    {label:'Fecha ingreso',value:'f_ingreso'},
    {label:'Hora ingreso',value:'h_ingreso'},
    {label:'Usuario ingreso',value:'usuario_ingreso'},
    {label:'Cupón',value:'nro_cupon'},
    {label:'Recibo UAD',value:'nro_comprobante'},
    
    ]

    
export const Ingreso = ({codigo,usuario})=>{
    const [consulta,setConsulta] = useState(false)
    const [codigoweb,setCodigoWeb] = useState(null)
    const [dni,setDni] = useState(null)
    const [inscripto,setInscripto] = useState(null)
    const [loader,setLoader] = useState(false)
    const [verDetalle,setVerDetalle] = useState(false)
    const [inscriptos,setInscriptos] = useState(false)
    const [cargando,setCargando] = useState(false)
    const [texto,setTexto] = useState('')
    const [tipo,setTipo] = useState('dni')
    const [resultados,setResultados] = useState([])

    useEffect(()=>{
        cargarInscriptos()
    },[])

    const cargarInscriptos =  async (id_evento,sinLoading)=>{
        
            setCargando(true)
        try{
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/tablasgenerales/eventos/inscriptos/resumen/${process.env.REACT_APP_ID_EVENTO}`:`https://uadapi.uad.digital/api/tablasgenerales/eventos/inscriptos/resumen/${process.env.REACT_APP_ID_EVENTO}`)
            
            setInscriptos(data)
            setCargando(false)
        }catch(err){
           alert(err.response.data.message)
           setCargando(false)
        }
    }

    useEffect(()=>{
        setInscripto(null)
    },[codigoweb,dni])

    const validarCodigoWeb = async ()=>{
        try{
            setLoader(true)
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/colaborador/consulta/${codigo}/${usuario.nombre_usuario}/${process.env.REACT_APP_ID_EVENTO}/${codigoweb}/cw`:`https://uadapi.uad.digital/api/eventos/colaborador/consulta/${codigo}/${usuario.nombre_usuario}/${process.env.REACT_APP_ID_EVENTO}/${codigoweb}/cw`)
            setInscripto(data)
            setLoader(false)
        }catch(err){
            alert(err?.response?.data?.message || 'Error de validación 3')
            setLoader(false)
        }
    }

    const validarDni = async ()=>{
        try{
            setLoader(true)
            const {data} = await Axios.get(process.env.NODE_ENV =='development' ? `http://localhost:3020/api/eventos/colaborador/consulta/${codigo}/${usuario.nombre_usuario}/${process.env.REACT_APP_ID_EVENTO}/${dni}/dni`:`https://uadapi.uad.digital/api/eventos/colaborador/consulta/${codigo}/${usuario.nombre_usuario}/${process.env.REACT_APP_ID_EVENTO}/${dni}/dni`)
            setInscripto(data)
            setLoader(false)
        }catch(err){
            alert(err?.response?.data?.message || 'Error de validación 4')
            setLoader(false)
        }
    }

    

    const buscarTexto = ()=>{
        debugger
        if(texto){

            if(texto.length>4){
                let resultado;

                switch(tipo){
                    case 'dni' :
                        resultado = inscriptos.inscriptos.filter(item=>item.nro_documento?.toString().includes(texto));
                        break;
                    case 'cweb':
                        resultado = inscriptos.inscriptos.filter(item=>item.codigo_web?.toString().includes(texto));
                        break;                   
                    case 'mpid':
                        resultado = inscriptos.inscriptos.filter(item=>item.mp_id?.toString().includes(texto));
                        break;
                    case 'apellido':
                        resultado = inscriptos.inscriptos.filter(item=>item.nombre?.toString().toUpperCase().includes(texto.toUpperCase()));
                        break;
                    default:
                        resultado = inscriptos.inscriptos.filter(item=>item.nombre?.toString().toUpperCase().includes(texto.toUpperCase()));
                }
                if(resultado.length>0){
                    setResultados(resultado)
                }else{
                    const criterio = criteriosBusqueda(tipo,texto)
                    alert(criterio + ' No se encontraron resultados')
                }
            }else{
                alert('Ingrese al menos 5 caracteres en el texto de búsqueda')
            }

        }else{
            alert('Falta ingresar un texto en el campo de búsqueda')
        }
    }

    const ingresarInscripto = async (item)=>{
        try{
            setLoader(true)
            const objetoaEnviar = {id_usuario:usuario.id_usuario,
                                    mp_id: item.mp_id ? item.mp_id : null,
                                    nro_cupon: item.nro_cupon ? item.nro_cupon : null,
                                    inscriptos:[item.id_evento_inscr]
                                }
            const {data} = await Axios.post(process.env.NODE_ENV =='development' ? `http://localhost:4002/api/tablasgenerales/evento/ingreso/inscripto/${process.env.REACT_APP_ID_EVENTO}`:`https://administracion.uad.digital/api/tablasgenerales/evento/ingreso/inscripto/${process.env.REACT_APP_ID_EVENTO}`,objetoaEnviar)
            setInscripto(data)

            if(dni){
                validarDni()      
                setTimeout(() => {
                    setDni('')
                }, 3000);   
            }
            if(codigoweb){
                validarCodigoWeb()  
                setTimeout(() => {
                    setCodigoWeb('')
                }, 3000);         
            }

            setLoader(false)
        }catch(err){
            alert(err?.response?.data?.message || 'Error de ingreso')
            setLoader(false)
        }
    }

    const handlechange = (e)=>{
        setTexto(e.target.value)
    }

    const handleChangeCodigoWeb = (e)=>{
        setDni('')
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setCodigoWeb(e.target.value)
        }
    }

    const handleChangeDni = (e)=>{
        setCodigoWeb('')
        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()==''){
            setDni(e.target.value)
        }
    }
    
    return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        
        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Ingresa un inscripto:</Typography>
        
        <TextField autoFocus inputProps={{maxLength: 9}}  value={dni} placeholder="DNI" onChange={handleChangeDni} type="text"/>
        
        {dni && <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
            <Button variant="contained" color="primary" onClick={validarDni}>Validar DNI</Button>
            <IconButton style={{marginLeft:'1rem'}} onClick={()=>setDni('')}><DeleteIcon /></IconButton>
        </Box>}
        <br />
        <TextField inputProps={{maxLength: 4}}  value={codigoweb} placeholder="Ingrese el código web" onChange={handleChangeCodigoWeb} type="text"/>
        
        {codigoweb && <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
            <Button variant="contained" color="primary" onClick={validarCodigoWeb}>Validar código web</Button>
            <IconButton style={{marginLeft:'1rem'}} onClick={()=>setCodigoWeb('')}><DeleteIcon /></IconButton>
        </Box>}
        
        {inscripto && inscripto?.length==0 && <Alert style={{fontSize:'large'}} severity="warning" variant=''>No se encontraron datos</Alert> }

        {inscripto && inscripto?.length>0 && <Box>
            <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">{inscripto[0]?.detalle}</Typography>

            {inscripto.map(item=><div style={{border:'solid 1px gray',padding:'15px',marginTop:'0.5rem',marginBottom:'0.5rem', background:'#3f51b5'}}>
                <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">{item.nombre}</Typography>
                <p>DNI {item.nro_documento} {item.sexo=='M' ? 'HOMBRE' : 'MUJER'}</p>
                <h3 className='color-tomato'>{item?.categoria ? item?.categoria : ''}</h3>
                <p>{item.edad_actual} años</p>
                {!item.presente && <Button onClick={()=>ingresarInscripto(item)} color="secondary" variant="contained">Ingresar</Button>}
                {item.presente && <div><Alert style={{fontSize:'large'}} severity="info" variant='filled'>
                                        INGRESADO
                                </Alert>
                                    <div style={{width:'280px',display:'flex',flexDirection:'column'}}>
                                        <span>Ingresa el {item?.fecha_ingreso}</span>
                                        <span>a las {item?.hora_ingreso}</span>
                                        <span>por {item?.usuario_ingreso}</span>
                                    </div>
                                </div>}
            </div>)}    
        </Box>}

        <Loader open={loader} 
                children={<Logo width='300px'/>}
                texto='Cargando...'/>

        {inscriptos.inscriptos && <Box> 
                <div className="text-center relative" style={{border:'1px solid gray',padding:'15px',marginTop:'30px'}}>
                    <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Resumen de inscriptos</Typography>
                    <div style={{display:'flex',width:'x',marginRight:'auto',marginLeft:'auto',justifyContent:'center'}}>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Inscriptos</p>
                                    <p className="fw-700">{inscriptos.inscriptos.length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Ingresados</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.presente==1).length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >UAD</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.categoria=='UAD').length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Externos</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.categoria=='EXT').length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Becados</p>
                                    <p className="fw-700">UAD {inscriptos.inscriptos.filter(item=>item.cod_interno=='becado' && item.categoria=='UAD').length}</p>
                                    <p className="fw-700">EXT {inscriptos.inscriptos.filter(item=>item.cod_interno=='becado' && item.categoria=='EXT').length}</p>
                                </div>
                    </div>
                    <Excel  datos={inscriptos.inscriptos} 
                    descripcion='Inscriptos'
                    columnas={columnas}
                    titulo='Inscriptos Convención' />
                </div>

                <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                
                        <Typography style={{marginTop:'0.5rem',marginBottom:'0.5rem'}} variant="h6">Busca un inscripto:</Typography>

                        <Box sx={{marginTop:'0.5rem', display:'flex', maxWidth:''}}>
                            <TextField inputProps={{min: 4}} value={texto} placeholder="Ingrese un texto" onChange={handlechange} type="text"/>
                        <Button title='Borrar nacionalidad' onClick={()=>setTexto('')}><DeleteOutlineIcon/></Button>                          
                        </Box>

                        <FormControl>
                            <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>Criterio de búsqueda:<span className='c-obl'></span></FormLabel>
                            <RadioGroup
                                value={tipo}
                                onChange={(e)=>{
                                    setTipo(e.target.value)
                                }}>
                                    <Box sx={{display:'flex',justifyContent:'center'}}>
                                        <FormControlLabel value="dni" control={<Radio />} label="DNI" />
                                        <FormControlLabel value="mpid" control={<Radio />} label="Número operación MP" />
                                        <FormControlLabel value="apellido" control={<Radio />} label="Apellido" />
                                        <FormControlLabel value="cweb" control={<Radio />} label="Código web" />
                                    </Box>
                            </RadioGroup>
                        </FormControl>

                        <Button onClick={buscarTexto} variant="contained" color='secondary'>
                                Buscar
                        </Button>
                </div>

              
                    {/*JSON.stringify(resultados)*/}
                    {resultados.length>0 && <Box>
                            {JSON.stringify(resultados)}
                </Box>}

            <Dialogos open={resultados.length>0} 
                    titulo={criteriosBusqueda(tipo,texto)} 
                    //fullscreen={true}
                    closeHeader
                    procesarCancelar = {()=>setResultados([])}
                   >
            <Alert sx={{justifyContent:'center'}} severity="xxx" variant='xxx'>
            </Alert>      
            <Box>
                    {resultados.map(item=><Card>
                        <CardContent style={{background:'#b8d7e8',margin:'10px'}}>
                            <h4 title={JSON.stringify(item)}>{item.nombre}</h4>
                            <p>DNI {item.nro_documento} ({item.sexo=='M' ? 'HOMBRE' : 'MUJER'}) {item.edad} años</p>
                            <p>Provincia: {item.provincia} Región: {item.region}</p>
                            <p>Num. mercadopago: {item.mp_id} Código web: {item.codigo_web}</p>
                            <p>{item.detalle} {item.nro_cupon ? `(Contingente - cupón: ${item.nro_cupon})` : ''}</p>
                            <p>E-mail: {item.email}</p>
                            <p>Tipo: {item.categoria} Rango: {item.rango}</p>
                            {item.presente && <> 
                                <p>Ingresado por: {item.usuario_ingreso} el {item.f_ingreso} {item.h_ingreso}</p>
                            </>}
                        </CardContent>
                    </Card>)}    
            </Box>              
            {/*JSON.stringify(resultados)*/}
        </Dialogos>
        </Box>
}
    
</div>

    if (loader){
            return <div>
            <Loader open={true} 
               children={<Logo width='300px'/>}
               texto='Cargando evento...'/>
           </div>  
    }

    if(!inscriptos?.inscriptos){
        return <div>
        <Loader open={true} 
           children={<Logo width='300px'/>}
           texto='Cargando evento...'/>
       </div>  
    }/*else{
        return   <Excel  datos={inscriptos.inscriptos} 
        descripcion='Inscriptos'
        columnas={columnas}
        titulo='Inscriptos Convención' />
    }*/
else{
    return <Box> 
                <div className="text-center relative">
                    <div style={{display:'flex',width:'500px',marginRight:'auto',marginLeft:'auto',justifyContent:'center'}}>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Inscriptos</p>
                                    <p className="fw-700">{inscriptos.inscriptos.length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Ingresados</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.presente==1).length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >UAD</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.categoria=='UAD').length}</p>
                                </div>
                                <div title="Registrados" className="a-psts-c5 p-1">
                                    <p className="text-small w-80 text-center" >Externos</p>
                                    <p className="fw-700">{inscriptos.inscriptos.filter(item=>item.categoria=='EXT').length}</p>
                                </div>
                    </div>
                    <Excel  datos={inscriptos.inscriptos} 
                    descripcion='Inscriptos'
                    columnas={columnas}
                    titulo='Inscriptos Convención' />
                </div>
                <Box sx={{marginTop:'0.5rem', display:'flex'}}>
                    <TextField autoFocus value={texto} onChange={handlechange} fullWidth id="ub-dni" label="Ingrese su DNI" variant="outlined" />
                    
                    {texto && <Button onClick={buscarTexto}>
                            Buscar
                    </Button>}
                </Box>
                <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>Sexo<span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={tipo}
                            onChange={(e)=>{
                                setTipo(e.target.value)
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="dni" control={<Radio />} label="DNI" />
                                    <FormControlLabel value="mpid" control={<Radio />} label="Número operación MP" />
                                    <FormControlLabel value="apellido" control={<Radio />} label="Apellido" />
                                    <FormControlLabel value="cweb" control={<Radio />} label="Código web" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    {JSON.stringify(resultados)}
                    {resultados.length>0 && <Box>
                            {JSON.stringify(resultados)}
                </Box>}
        </Box>

}

}

const criteriosBusqueda = (tipo,texto) =>{
    switch(tipo){
        case 'dni' :
            return `Búsqueda por DNI (${texto}) `
        case 'cweb':
            return `Búsqueda por código web (${texto}) `
        case 'mpid':
            return `Búsqueda por número de mercadopago (${texto}) `
        case 'apellido':
            return `Búsqueda por apellido (${texto}) `
        default:
            return `Búsqueda no identificada `
    }
}